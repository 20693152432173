import { noCase } from "change-case";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import {
  deepRemoveDuplicates,
  simpleDate,
  unformatCurrency,
} from "../../../components/tools";
import { getUserRole, useGetAuth } from "../../../contexts/AuthContext";
import { fetch } from "../../../tools/api";
import { queryArray } from "../../eWalletComponents/home/main/filter/modal/content/parent";
import { invoiceFormatter } from "./formatter";

export const invoicesValidation = (array) => {
  const result = (array || []).map((item) => {
    const { is_duplicate } = item;
    const { is_valid, is_match_name } = item?.local_recipient || {};

    if (is_duplicate)
      return {
        ...item,
        duplicate: is_duplicate,
        errorTexts: {
          title: "Duplicate Document",
          text: "Invoice already exist",
        },
      };

    if (!is_valid)
      return {
        ...item,
        invalid: !is_valid,
        errorTexts: {
          title: "Invalid Data",
          text: "Bank account number not registered.",
        },
      };

    if (!is_match_name)
      return {
        ...item,
        invalid_mismatch: !is_match_name,
        errorTexts: { title: "Mismatch", text: "Account name doesn’t match." },
      };

    return item;
  });

  return result;
};

export const useFilteredInvoiceData = () => {
  const { query } = useRouter();
  const { date: dateRaw, status, search_box } = query || {};
  const time = (date) => new Date(date).getTime();
  const date = time(dateRaw);

  const filteredInvoiceData = (array) => {
    const defaultArray = invoiceFormatter(array).filter(
      ({ state }) => state !== "created"
    );

    const filteredByState = defaultArray.filter(({ state }) => {
      if (!status) return true;

      const isPending = status == "pending";
      const newStatus = isPending
        ? ["waiting_for_approval", "waiting_for_confirmation"]
        : [status];

      return newStatus.includes(state);
    });

    const filteredByDate = filteredByState.filter(({ created_at }) => {
      if (!dateRaw) return true;
      const createdAtTime = time(created_at);
      return createdAtTime > date;
    });

    const filteredBySearchBox = filteredByDate.filter((item) => {
      if (!search_box) return true;

      const { invoice_number } = item || {};

      return noCase(invoice_number).includes(noCase(search_box));
    });
    return filteredBySearchBox;
  };

  return { filteredInvoiceData };
};

export const useInvoicePayment = () => {
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const { data, refetch, setData } = fetch({
    url: `/invoice_transactions?page=${page}`,
    afterSuccess: (res) => {
      setPage((p) => {
        const { total_page } = res || {};
        if (page == total_page) setLoading(false);
        if (!total_page) return p;

        const canSubmit = total_page > p;
        if (canSubmit) return p + 1;

        return p;
      });
    },
    formatter: (res, prev) => {
      const { data: prevData = [] } = prev || {};

      const data = deepRemoveDuplicates(
        [...prevData, ...(res?.data || [])],
        "id"
      );

      const result = { ...res, data };

      return result;
    },
    woInit: true,
    defaultValue: {},
  });

  useEffect(refetch, [page]);

  const { data: array = [], total_drafted_invoice } = data || {};

  const { isAdminOrSuperAdmin } = getUserRole();
  const { user } = useGetAuth();
  const { id } = user || {};

  const hasRequired = (approval_tasks) => {
    const unapprovedArr = approval_tasks.filter(
      ({ approved_at }) => !approved_at
    );
    const requireApprove = unapprovedArr.some(({ user_id }) => user_id === id);

    return requireApprove;
  };

  const hasMyApproval = (approval_tasks) => {
    const anyApproval = approval_tasks.some(({ user_id }) => user_id === id);

    return anyApproval;
  };

  const invoiceTransactions = invoiceFormatter(array).filter(
    ({ state }) => state !== "created"
  );

  const tableArrayAll = invoiceTransactions
    .filter(({ state }) => state != "drafted")
    .filter((item) => {
      const { approval_tasks, user_id } = item || {};

      if (!isAdminOrSuperAdmin) {
        if (user_id == id) return true;
        return false;
      }
      //12-12 CHANGE FROM ISREQUIRED TO MYAPPROVAL TO SHOW ALL INVOICE
      const myApproval = hasMyApproval(approval_tasks);

      if (myApproval) return true;
      if (user_id == id) return true;

      return false;
    });

  const tableArrayDraft = invoiceTransactions.filter(
    ({ state, user_id }) => state == "drafted" && user_id == id
  );

  const tableArrayRequire = invoiceTransactions.filter(
    ({ state, rejection_note, approval_tasks }) => {
      if (rejection_note) return false;

      const isRequired = hasRequired(approval_tasks);

      if (!isRequired) return false;

      const isWaitingForApproval = state == "waiting_for_approval";
      return isWaitingForApproval;
    }
  );

  return {
    tableArrayAll,
    tableArrayRequire,
    tableArrayDraft,
    loading,
    setData,
    draftedNumber: total_drafted_invoice,
  };
};

export const useInvoicePaymentLazy = ({ type }) => {
  const { push, query } = useRouter();
  const { runQuery } = query;
  const [isFirstRender, setIsFirstRender] = useState(true);
  const [page, setPage] = useState(1);
  const { queries } = useQueriesInvoice();

  const isTask = type === "task";

  const urlDecider = (type) => {
    if (type === "task")
      return `/invoice_transactions/index_requested?page=${page}&per_page=15${queries}`;
    return `/invoice_transactions?page=${page}&per_page=15&q[state_does_not_match_all][]=drafted&q[state_does_not_match_all][]=created${queries}`;
  };

  const { data, refetch, setData, loading } = fetch({
    url: urlDecider(type),
    formatter: (res, prev) => {
      const { data: prevData = [] } = prev || {};

      const data = deepRemoveDuplicates(
        [...prevData, ...(res?.data || [])],
        "id"
      );

      const result = { ...res, data };

      return result;
    },
    woInit: true,
    defaultValue: {},
  });

  useEffect(refetch, [page]);

  const {
    data: array = [],
    total_page,
    total_drafted_invoice,
    total_invoice,
  } = data || {};

  const invoiceTransactions = invoiceFormatter(array);

  const tableArrayAll = invoiceTransactions;

  const handleScroll = () => {
    if (page >= total_page) return;
    return setPage((p) => p + 1);
  };

  useEffect(() => {
    if (isFirstRender) return setIsFirstRender(false);
    if (!runQuery) return;

    //set data to empty object
    setData({});

    //if page === 1 do refetch if not set page to 1
    if (page == 1) return refetch();
    setPage(1);
    return;
  }, [runQuery]);

  return {
    tableArrayAll,
    loading,
    setData,
    draftedNumber: total_drafted_invoice,
    totalTasks: total_invoice,
    handleScroll,
  };
};

export const useQueriesInvoice = () => {
  const { query } = useRouter();
  const {
    search_box = "",
    from_date,
    to_date,
    status,
    keywords,
    specific_amount,
    minimum_amount,
    maximum_amount,
  } = query;

  const statusArr = queryArray(query, "status");

  const searchBoxUrl = () => {
    const search = keywords || search_box;
    if (!search) return "";
    return `&q[invoice_number_or_description_or_recipient_name_cont]=${search}`;
  };

  const fromUrl = () => {
    if (!from_date) return "";
    const fromUrlFormatted = new Date(`${simpleDate(from_date)} 00:00`);
    return `&q[created_at_gteq]=${fromUrlFormatted}`;
  };

  const toUrl = () => {
    if (!to_date) return "";
    const toDateFormatted = new Date(`${simpleDate(to_date)} 23:59`);
    return `&q[created_at_lteq]=${toDateFormatted}`;
  };

  const statusUrl = statusArr
    .map((status) => `&q[state_matches_any][]=${status}`)
    .join("");

  const amountUrl = () => {
    const getNumber = (number) => Number(unformatCurrency(number));
    // const noQuery = !specific_amount && !minimum_amount && !maximum_amount;
    // if (noQuery) return "";

    const amountGTEQ = `&q[total_amount_gteq]=${getNumber(minimum_amount)}`;
    const amountLTEQ = `&q[total_amount_lteq]=${getNumber(maximum_amount)}`;

    if (specific_amount)
      return `&q[total_amount_eq]=${getNumber(specific_amount)}`;
    if (minimum_amount && maximum_amount) return `${amountGTEQ}${amountLTEQ}`;
    if (minimum_amount) return amountGTEQ;
    if (maximum_amount) return amountLTEQ;
    return "";
    // return amountLTEQ;
  };

  const queries = `${searchBoxUrl()}${toUrl()}${fromUrl()}${statusUrl}${amountUrl()}`;
  return { queries };
};
